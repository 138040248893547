.container {
  //max-width: 300px;
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid transparent;
  padding-bottom: 50px;
  position: relative;
  transition: 0.5s all ease-in-out;
  margin: 30px 0;
}

.noActive{
  border: 1px solid #eeeeee;
}

.active{
  filter: drop-shadow(1px 1.732px 14.5px rgba(0, 0, 0, 0.2));
}

.deactivate{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #edf0f7 0%, #edf0f7 32%, #ffffff 100%);
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  border-radius: 4px;
  transition: 0.5s all ease-in-out;
  opacity: 0;
}

.opacityFull{
  opacity: 1;
}

.img {
  width: 100%;
  display: flex;
  border-radius: 4px;
  padding-top: clamp(150px, 80%, 200px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.shadow {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #676767 0%, #dcdada 100%);
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  border-radius: 4px;
}

.content {
  max-width: 620px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
}

.tag {
  font-size: 14px;
  letter-spacing: 0px;
  color: #e0131a;
  font-weight: 500;
  font-family: "Roboto";
  flex-shrink: 0;
}

.title {
  font-size: 22px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
  font-family: "Roboto";
  padding-bottom: 15px;
  padding-top: 20px;
}

.descriptionContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  hyphens: auto;
}

.btnGo {
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a3a3a3;
  font-weight: 600;
  font-family: "Encode Sans";
  cursor: pointer;
  user-select: none;
  position: absolute;
  bottom: 30px;
  z-index: 10;

  &::after {
    content: "";
    width: 7px;
    height: 12px;
    padding-right: 30px;
    background-image: url("../../../assets/img/other/arrowRight.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btnBottom {
  bottom: 0;
}