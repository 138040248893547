.text {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
}

.textStrong {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
}

.text2 {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 400;
    font-family: "Roboto";
}

.textMedium {
    font-size: 20px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
}

.textBig {
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto";
}

.textSmall {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    margin: 0;
}

.textSmall2 {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 400;
    font-family: "Roboto";
    margin: 0;
}

.textUltraSmall {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    flex-shrink: 0;
}

.btnGreyDark {
    //all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    min-width: 150px;
    width: 100%;
    max-height: 60px;
    height: 100%;
    border-radius: 20px;
    background-color: #707070;
    border: 1px solid #707070;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Roboto";
    user-select: none;
    cursor: pointer;
    margin: 5px 0;
}

.btnGrey {
    //all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    min-width: 150px;
    width: 100%;
    max-height: 60px;
    height: 100%;
    border-radius: 20px;
    background-color: #f3f3f3;
    border: 1px solid #d9d9d9;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 500;
    font-family: "Roboto";
    user-select: none;
    cursor: pointer;
    margin: 5px 0;
    transition: 0.5s all ease-in-out;

    &:hover {
        box-shadow: 2px 2px 15px rgba(#000000, 0.1);
    }
}

.btnPrev {
    max-width: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 60px;
    user-select: none;
    cursor: pointer;

    p {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        margin: 0 0 10px 5px;
    }

    &::before {
        content: " ";
        width: 8px;
        height: 16px;
        background-image: url("../../../assets/img/Cart/btnBackBlack.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: - 15px;
    }

}

.btnPrevAddress{
    margin: 0;
}

.btnChange {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 18px;
    color: #141414;
    font-weight: 400;
    font-family: "Roboto";
    cursor: pointer;
    user-select: none;
    padding: 5px;
    margin: 0 30px 30px;
}

@media screen and (max-width: 830px) {
    .btnGrey {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .btnPrev {
        margin-left: 10px;
    }
}