.container {
  display: flex;
  flex-direction: column;
  width: 251px;
  position: relative;
}

.field {
  flex-shrink: 0;
  padding-left: 20px;

  font-size: 16px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #484848;
  font-weight: 400;
  font-family: "Roboto";

}

.container2 {
  display: flex;
  flex-direction: column;
  position: relative;
}

.field2 {
  flex-shrink: 0;
  padding-left: 20px;

  font-size: 16px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #484848;
  font-weight: 400;
  font-family: "Roboto";

}

.optionsMenu {
  display: flex;
  flex-flow: column;
  border: 1px solid #e5e5e5;
  width: calc(100% - 8px);
  background-color: #ffffff;
  overflow: hidden;
  position: absolute;
  top: 41px;
  left: 4px;
  transition: 0.3s height ease-in-out, 0.3s background-color, 0.3s opacity;
  z-index: 1000;
  height: 0;
  opacity: 0;
}

.optionsMenu2 {
  display: flex;
  flex-flow: column;
  border: 1px solid #e5e5e5;
  width: calc(100% - 8px);
  background-color: #ffffff;
  overflow: hidden;
  position: absolute;
  top: 48px;
  left: 4px;
  transition: 0.3s height ease-in-out, 0.3s background-color, 0.3s opacity;
  z-index: 1000;
  height: 0;
  opacity: 0;
}

.menuButton {
  width: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #484848;
  font-weight: 400;
  font-family: "Roboto";

  padding-left: 17px;
  padding-top: 5px;
  padding-bottom: 5px;

  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background-color: #f5f5f5;
  }
}

.selectButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 251px;
  height: 42px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  z-index: 1001;
}

.selectButton2 {
  display: flex;
  align-items: center;
  max-width: 398px;
  width: 100%;
  height: 52px;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
  padding: 15px;
  z-index: 1001;
  cursor: pointer;
}

.arrow {
  flex-shrink: 0;
  width: 12px; //12
  height: 7px; //7
  background-image: url("../../assets/img/other/arrowDownGray.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s all ease-in-out;
  position: absolute;
  top: calc(50% - 3.5px);
  right: 15px;
}

.open {
  transform: rotate(180deg);
}

.label {
  background-color: #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  color: #989898;
  font-weight: 400;
  font-family: "Roboto";
  position: absolute;
  top: -12.5px;
  left: 10px;
  z-index: 1010;
  padding: 5px;
}

.sliderOpen {
  height: auto !important;
}

@media screen and (max-width: 1350px) {
  .container {
    width: calc(251px * 0.7);
  }

  .optionsMenu {
    top: 28px;
  }

  .selectButton {
    width: calc(251px * 0.7); //251px;
    height: calc(42px * 0.7); //42px;
  }

  .field {
    font-size: 14px;
  }

  .menuButton {
    font-size: 14px;
  }

  .label {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 28px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media screen and (max-width: 1050px) {
  .container {
    width: 251px;
  }

  .optionsMenu {
    top: 41px;
  }

  .selectButton {
    width: 251px; //251px;
    height: 42px; //42px;
  }

  .field {
    font-size: 16px;
  }

  .menuButton {
    font-size: 16px;
  }

  .label {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 40px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 800px) {
  .label {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: calc(251px * 0.7);
  }

  .optionsMenu {
    top: 28px;
  }

  .selectButton {
    width: calc(251px * 0.7); //251px;
    height: calc(42px * 0.7); //42px;
  }

  .field {
    font-size: 14px;
  }

  .menuButton {
    font-size: 14px;
  }

  .label {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
}