.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
        max-width: 1150px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;

        .titleContainer {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: clamp(24px, 9vw, 50px) 0 clamp(24px, 9vw, 50px) 0;

            .title {
                min-width: 200px;
                font-size: 24px;
                color: #050505;
                font-weight: 600;
                font-family: "Encode Sans";
            }

            .title2 {
                min-width: 200px;
                font-size: 18px;
                letter-spacing: 0px;
                color: #636363;
                font-weight: 500;
                font-family: "Roboto";
            }

            .tag {
                min-width: 280px;
                font-size: 18px;
                letter-spacing: 0px;
                color: #e0131a;
                font-weight: 500;
                font-family: "Roboto";
            }

            .descriptionContainer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                column-gap: 50px;
            }

            .description {
                font-size: 14px;
                letter-spacing: 0px;
                color: #7e7e7e;
                font-weight: 600;
                font-family: "Encode Sans";
                padding: 2px;
            }
        }

        .titleContainer2 {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .title {
                font-size: clamp(18px, 5vw, 24px);
                letter-spacing: 0px;
                color: #000000;
                font-weight: 600;
                font-family: "Encode Sans";
                hyphens: auto;
            }
        }

        .subtitleContainer {
            display: flex;
            flex-direction: column;
            margin: clamp(24px, 9vw, 50px) 0 clamp(24px, 9vw, 50px) 0;

            .title,
            h2 {
                font-size: clamp(17.5px, 5vw, 24px);
                letter-spacing: 0px;
                color: #000000;
                font-weight: 600;
                font-family: "Encode Sans";
                hyphens: auto;
                margin-bottom: clamp(10px, 3vw, 40px);
            }

            h3 {
                font-size: clamp(17.5px, 5vw, 24px);
                line-height: 24px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                hyphens: auto;
                margin-bottom: clamp(10px, 3vw, 40px);
            }

            ul {
                font-size: clamp(17.5px, 5vw, 24px);
                letter-spacing: 0px;
                color: #000000;
                font-weight: 600;
                font-family: "Encode Sans";
                hyphens: auto;
                margin-bottom: 20px;
                padding-left: 0;
            }

            p {
                font-size: 18px;
                line-height: 24px;
                color: #000000;
                font-weight: 400;
                font-family: "Roboto";
            }

            .space {
                height: clamp(10px, 3vw, 40px);
            }

            .bullet,
            li {
                display: flex;
                align-items: center;
                font-size: clamp(16px, 4vw, 18px);
                letter-spacing: 0px;
                line-height: 30px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                margin-left: 40px;

                &::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    margin-right: 20px;
                    background-color: #3b3bf0;
                }
            }
        }

        .btnSearch {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("../../assets/img/Top/loop.svg");
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            width: 70px;
            height: 61px;
            filter: drop-shadow(0 0 1.5px rgba(157, 157, 157, 0.85));
            background-color: #ffffff;
            user-select: none;
            cursor: pointer;
            position: absolute;
            right: 15px;
        }

        .tagSmall,
        h4 {
            font-size: 16px;
            color: #383838;
            font-weight: 500;
            font-family: "Encode Sans";
            margin-top: 40px;
            padding-left: 15px;
        }

        .containerWhite {
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ffffff;
        }

        .containerGrey {
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #f7f7f7;
        }

        .containerGreyEffect {
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 2px;
            box-shadow: inset 1px 1.732px 12px rgba(0, 0, 0, 0.07);
            background-color: #fafafa;
        }

        .content {
            max-width: 980px;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 15px;
            position: relative;
        }

        .center {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .small {
            max-width: 950px;
        }

        .medium {
            max-width: 1200px;
            width: clamp(100%, 85vw, 120%);
            padding-left: 0px;
            padding-right: clamp(80px, 20vw, 180px);
        }

        .big {
            max-width: 1290px;
        }

        .moreSpace {
            height: 60px;
        }

        .textContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 15px;
            margin-top: clamp(10px, 6vw, 40px);
            margin-bottom: clamp(50px, 12vw, 140px);

            p {
                font-size: 18px;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
                font-family: "Roboto";
                margin: 8px;
            }

            span {
                color: #e31111;
            }
        }

        .commentContainer {
            width: 100%;
            display: flex;
            border-radius: 12px;
            filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.06));
            background-color: #f8f8f8;
            margin-top: 50px;
            padding: 30px 15px;
            row-gap: 20px;
            column-gap: 20px;
        }

        .allComments{
            width: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.06));
            background-color: #f8f8f8;
            margin-top: 50px;
            padding: 30px 15px;
            row-gap: 20px;
            column-gap: 20px;
        }

        .allComments > .commentContainer {
            margin-top: unset;
            filter: unset;
            padding: 0 15px;
        }

        .column {
            width: 100%;
            max-width: clamp(185px, 18vw, 250px);
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 5px;
            position: relative;
        }

        .columnBig {
            max-width: unset;
            align-items: flex-start;
            min-width: 250px;
        }

        .columnSmall {
            max-width: clamp(0px, 14.5vw, 250px);
        }

        input,
        .data {
            all: unset;
            width: calc(100% - 30px);
            min-height: 30px;
            border-radius: 12px;
            background-color: #ffffff;
            padding: 15px;
            font-size: 19px;
            color: #000000;
            font-weight: 400;
            font-family: "Encode Sans";
        }

        input::placeholder {
            all: unset;
            font-size: 18px;
            color: #050505;
            font-weight: 400;
            font-family: "Encode Sans";
        }

        textarea,
        .text {
            all: unset;
            width: calc(100% - 30px);
            min-height: 30px;
            border-radius: 12px;
            background-color: #ffffff;
            padding: 15px;

            font-size: 19px;
            color: #000000;
            font-weight: 400;
            font-family: "Encode Sans";
            margin-bottom: 50px;
        }

        textarea::placeholder {
            all: unset;
            font-size: 18px;
            color: #050505;
            font-weight: 400;
            font-family: "Encode Sans";
        }

        .author {
            padding: 15px;
            font-size: 19px;
            color: #000000;
            font-weight: 400;
            font-family: "Encode Sans";
            text-align: center;
        }

        .height {
            height: 250px;
        }

        .minHeight {
            min-height: 120px;
        }

        .img {
            display: flex;
            width: 170px;
            height: 167px;
            margin-top: 15px;
            background-image: url("../../assets/img/Post/commentIcon.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .titleComment {
            font-size: 24px;
            color: #050505;
            font-weight: 600;
            font-family: "Encode Sans";
            position: absolute;
            top: -80px;
        }

        .link {
            font-size: 18px;
            color: #050505;
            font-weight: 400;
            font-family: "Encode Sans";
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .postList {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 25px;
        row-gap: 25px;
        margin-bottom: 80px;
    }

    .postList>div {
        width: calc(50% - 14px);
    }
}

@media screen and (max-width: 800px) {
    .container .main .commentContainer {
        flex-direction: column;
        align-items: center;

        .title {
            top: -280px;
        }
    }
}

@media screen and (max-width: 700px) {
    .container .main .postList>div {
        width: 100%;
        max-width: 700px;
    }
}

@media screen and (max-width: 500px) {
    .container .main {
        .titleContainer2 {
            margin-top: 65px;
        }

        .medium {
            padding-right: 0px;
        }
    }
}